export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['¡Gran trabajo!', 'Asombroso', '¡Bien Hecho!']
export const GAME_COPIED_MESSAGE = 'Juego copiado al portapapeles'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'No hay suficientes letras'
export const WORD_NOT_FOUND_MESSAGE = 'No se encontró la palabra'
export const HARD_MODE_ALERT_MESSAGE =
  '¡El modo difícil solo se puede habilitar al principio!'
export const HARD_MODE_DESCRIPTION =
  'Cualquier pista revelada debe usarse en conjeturas posteriores'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Para mejorar la visión del color'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La palabra era: ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `La palabra debe contener ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Borrar'
export const STATISTICS_TITLE = 'Estadísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribución de Jugadas'
export const NEW_WORD_TEXT = 'Nueva palabra en'
export const SHARE_TEXT = 'Compartir'
export const LETS_PLAY_BUTTON = '¡Juguemos!'
export const INFO_MODAL_TITLE = '¿Cómo Jugar?'
export const MIGRATE_BUTTON_TEXT = 'Transferir'
export const MIGRATE_DESCRIPTION_TEXT =
  'Haga clic aquí para transferir sus estadísticas a un nuevo dispositivo.'
export const TOTAL_TRIES_TEXT = 'Intentos totales'
export const SUCCESS_RATE_TEXT = 'Tasa de Éxito'
export const CURRENT_STREAK_TEXT = 'Racha Actual'
export const BEST_STREAK_TEXT = 'Mejor Racha'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "Está utilizando un navegador integrado y puede tener problemas para compartir o guardar sus resultados. Le recomendamos que utilice el navegador predeterminado de su dispositivo."
