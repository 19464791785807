import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

import {
  LETS_PLAY_BUTTON,
  INFO_MODAL_TITLE
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title={INFO_MODAL_TITLE} isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Adivine la palabra en 6 intentos. Después de cada intento, el color de las fichas cambiará para mostrar qué tan cerca estuvo tu adivinar la palabra.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="M"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="R" />
        <Cell value="E" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      La letra <b>M</b> está en la palabra y en la posición correcta.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="present"
        />
        <Cell value="N" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra <b>A</b> está en la palabra, pero en la posición incorrecta.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="M" />
        <Cell value="A" />
        <Cell value="N" />
        <Cell isRevealing={true} isCompleted={true} value="T" status="absent" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra <b>T</b> no está en la palabra.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        Puede haber letras repetidas y en ese caso las pistas son independientes para cada letra y tienen prioridad.
      </p>
      <br />

      <button
        type="button"
        className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        onClick={handleClose}
      >
        {LETS_PLAY_BUTTON}
      </button>
    </BaseModal>
  )
}
