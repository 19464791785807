export const VALID_GUESSES = [
  'mareo',
  'pasto',
  'clavo',
  'bueno',
  'bujia',
  'joyas',
  'pista',
  'pasta',
  'salsa',
  'banca'
]
